/**
 * ======================================
 * Share Buttons
 * ======================================
 */

.c-share-buttons-container {
  margin-bottom: $supple-space-base;
}

.c-share-buttons__heading {
  font-size: $font-size-h4;
  font-weight: 700;
  margin-bottom: 0.75rem;
}

.c-share-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin: 0;
  padding: 0;
  list-style: none;
}

/**
 * ======================================
 * Elements
 * ======================================
 */

.c-share-buttons__button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: $border-radius-base;
  background-color: $color-gray-100;
  color: $color-gray-900;
  border: none;
  cursor: pointer;
  transition: $base-animation;

  &:hover,
  &:focus-visible {
    background-color: $color-blue-500;
    color: $color-white;
    outline: none;
  }

  &:focus-visible {
    @include generic-focus();
  }

  svg {
    width: 20px;
    height: 20px;
  }
}

.c-share-buttons__copy-button {
  position: relative;

  &::before {
    content: 'Gekopieerd!';
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%) translateY(-10px);
    background-color: $color-gray-900;
    color: $color-white;
    padding: 4px 8px;
    border-radius: $border-radius-base;
    font-size: $font-size-small;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    transition: opacity 200ms ease, transform 200ms ease, visibility 0s 200ms;
    z-index: 10;
  }
}

/**
 * ======================================
 * Modifiers
 * ======================================
 */

.c-share-buttons__copy-button--copied {
  &::before {
    opacity: 1;
    visibility: visible;
    transform: translateX(-50%) translateY(0);
    transition: opacity 200ms ease, transform 200ms ease, visibility 0s 0s;
  }

  background-color: $color-green-500;
  color: $color-white;
}
